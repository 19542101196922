import React from 'react';
import '../css/header.css';

class divButton extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hovering: false
        }
        
    }

    handleClick = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth'
        })
    }

    render(){
        let bgColor;
        if (window.innerWidth > 1000) {
            if(this.state.hovering){
                bgColor = {color: '#e9b24a', transition: '0.3s'}
            }else{
                bgColor = {color: 'white'}
            }
        }else{
            bgColor = {color: 'white'}
        }
        
        return(
            <a className="headerInside"
                onClick={() => {this.handleClick(this.props.scrollRef)}}
                style={bgColor}
                onMouseEnter={() => {this.setState({hovering: true})}} 
                onMouseLeave={() => this.setState({hovering: false})}
                >
                {this.props.title}
            </a>
        )

    }

}

export default divButton;