import React from "react";
import Logo from '../images/gpf_logo.png';
import "../css/header.css";

import HeaderButton from "../components/headerButton";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerStyle: "rgba(0, 0, 0, 0)",
      position: "absolute"
    };
  }

  listenScrollEvent = e => {
    if (window.scrollY/window.innerHeight > 0.5) {
      this.setState({
        position: "fixed",
        headerStyle: "rgba(0,0,0," + 1/(1+Math.pow(Math.E, -7*(window.scrollY/window.innerHeight - 0.7))) + ")"
      })
    }else{
      this.setState({
        position: "absolute",
        headerStyle: "rgba(0,0,0," + window.scrollY/window.innerHeight + ")"
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  handleClick = (ref) => {
    ref.current.scrollIntoView({
        behavior: 'smooth'
    })
  }

  render() {
      return (
            <div className={this.state.position === "absolute" ? "headerbar" : "animatebar"} 
            style={{
              ['--position-val']: this.state.position,
              ['--headerStyle']: this.state.headerStyle
             }}>
              <div className="logorefsep">
                <a 
                className="logoBox"
                onClick={() => {this.handleClick(this.props.homeRef)}}>
                  <img className="logoimg" src={Logo} alt=""/>
                </a>
                <div className="inlineHeader">

                  <div className="headerDiv">
                    <HeaderButton 
                      title="Home" 
                      show={true} 
                      headerClass="headerDiv"
                      scrollRef = {this.props.homeRef} 
                      ref = {this.props.home}
                      anchor="#home" 
                    />
                    <HeaderButton
                      title="About"
                      show={true}
                      headerClass="headerDiv"
                      scrollRef = {this.props.aboutRef}
                      anchor="#aboutme"
                    />
                    <HeaderButton
                      title="Portfolio"
                      show={true}
                      headerClass="headerDiv"
                      scrollRef = {this.props.portfolioRef}
                      anchor="#portfolio"
                    />
                    <HeaderButton
                      title="Contact"
                      show={true}
                      scrollRef = {this.props.contactRef}
                      headerClass="ContactDiv"
                      anchor="#contact"
                    />
                  </div>
                </div>
              </div>
              
            </div>
        
      );
  }
}

export default Header;
