import React from 'react';
import '../css/footer.css';

class Footer extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        };
    }
    
    componentDidMount() {
    }

    render(){
        return(
            <div className="footerbar">
                <p className="footerp">
                   Copyright © 2020 by Gustavo Flores. All rights reserved.
               </p>
            </div>
        )
    };
}

export default Footer;