import React from 'react';
import '../css/contact.css';

class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mobileAnimationTrigger: -1
        };
    }
    
    listenScrollEvent = e => {
        var contactdiv = document.getElementById(`contact${this.props.innerText}`)
        var contactbounds = contactdiv.getBoundingClientRect()
        if (Math.abs(document.body.getBoundingClientRect().height) - contactbounds.height > 
        contactbounds.y && window.innerWidth < 1000){
            this.setState({mobileAnimationTrigger: 1})
        }
    }
    
    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    render(){
        return(
            <div className="contactcontainer">
                {this.props.innerText}
                <div 
                    id={`contact${this.props.innerText}`}
                    className={this.state.mobileAnimationTrigger === -1 ? 
                    "contactwrapper" : "contactwrapperanimation"}>
                    <a href={this.props.href}
                        target="_blank" rel="noopener noreferrer"
                        className="contacthref">
                        <img 
                        className="contacticon"
                        src={this.props.img} alt=""/>
                    </a>
                </div>
            </div>
        )
    };
}

export default Contact;