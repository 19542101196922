import React from "react";
import '../css/portfolio.css';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedPortfolio: -1
    };
  }

  listenScrollEvent = e => {
    var portfoliodiv = document.getElementById(`portfolio${this.props.innerText}`)
    var portfoliobounds = portfoliodiv.getBoundingClientRect();
    if (Math.abs(document.body.getBoundingClientRect().height) - document.body.getBoundingClientRect().height/3 > 
    portfoliobounds.y && window.innerWidth < 1000){
        this.setState({selectedPortfolio: 1})
    }
    }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
      return (
        <div 
            id={`portfolio${this.props.innerText}`}
            className={this.state.selectedPortfolio !== -1 ? "portfolio" : "baseportfolio"} 
            onMouseEnter={() => {
                if (window.innerWidth > 1000){
                    this.setState({selectedPortfolio: 1})
                }
            
            }}

            onMouseLeave={() => {
                if (window.innerWidth > 1000){
                    this.setState({selectedPortfolio: 0})  
                }
            }}
        >
            <a target="_blank" rel="noopener noreferrer" href={this.props.href}>
                <img
                alt={`portfolio-img-${this.props.innerText}`} 
                src={this.props.img} 
                className={this.state.selectedPortfolio === -1 ? "hiddenportfolio" : "portfolioimg"}
                />
            </a>
            
            <div className={this.state.selectedPortfolio === 1 ? "portfoliotexthide" : "portfoliotext"}>
                {this.props.innerText}
            </div>
            <div className={this.state.selectedPortfolio === 1 ? "portfoliodeschide" : "portfoliodesc"}>
                {this.props.description}
            </div>
        </div>
        
      );
  }
}

export default Portfolio;