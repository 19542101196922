import React from "react";
import "./home.css";
import { curveMonotoneX, line, scaleLinear, select, selectAll, area } from 'd3';
import $ from 'jquery';
import { tooltip } from 'bootstrap';
import StarBG from './images/starsbgdarkened.jpg';
import StarBGMobile from './images/starsbgmobile.jpg';
import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
import Contact from './components/contact.jsx';
import Portfolio from './components/portfolio.jsx';
import BoaVista from './images/carousel_wings.jpg';
import Headshot from './images/headshot.png';
import GitIcon from './images/githubpng.png';
import Linkedin from './images/linkedinlogocircle.png';
import Resume from './images/documenticon.png';
import BlogImg from './images/blogimage.jpeg';

class Home extends React.Component {
    constructor(props){
    super(props);
    this.home = React.createRef(); 
    this.about = React.createRef(); 
    this.portfolio = React.createRef(); 
    this.contact = React.createRef(); 
    this.portTest = React.createRef();

        this.state = {
            renderedPoints: [],
            selectedPortfolio: [-1],
            headerStyle: {backgroundColor: "rgba(0, 0, 0, 0)"},
            fmtedData: {},
            bucketName: 'gustavopflores.com'
        };
    }
    
    getESTDate(date) {
        var dateObj = new Date(date)
        return ((dateObj.getHours() > 0 && dateObj.getHours() <= 12 ? dateObj.getHours() : dateObj.getHours() > 12 ? dateObj.getHours() - 12 : "12")
        + ":" + ("0" + dateObj.getMinutes()).substr(-2) + ":" + ("0" + dateObj.getSeconds()).substr(-2))
    }

    //Found here https://www.statisticshowto.com/normalized/
    featureScaling(min, max, value){
        return +(((value - min)/(max-min)).toFixed(4))
    }

  componentDidMount() {
    fetch(`https://s3.us-east-1.amazonaws.com/${this.state.bucketName}/data/latest.json`)
    // fetch('input.json')
    .then(response => response.json())
    .then(response => {
        var fmtedData = []
        var nasdaqMin = Number.MAX_VALUE
        var nasdaqMax = Number.MIN_VALUE
        var dowMin = Number.MAX_VALUE
        var dowMax = Number.MIN_VALUE
        var snpMin = Number.MAX_VALUE
        var snpMax = Number.MIN_VALUE

            for (let [, val] of (response["NASDAQ"]["chart"]["result"][0]["indicators"]["quote"][0]["open"]).entries()){
                nasdaqMax = Math.max(nasdaqMax, val)
                nasdaqMin = Math.min(nasdaqMin, val)
            }

            for (let [, val] of (response["DOW"]["chart"]["result"][0]["indicators"]["quote"][0]["open"]).entries()){
                dowMax = Math.max(dowMax, val)
                dowMin = Math.min(dowMin, val)
            }

            for (let [, val] of (response["SNP"]["chart"]["result"][0]["indicators"]["quote"][0]["open"]).entries()){
                snpMax = Math.max(snpMax, val)
                snpMin = Math.min(snpMin, val)
            }

            nasdaqMax = nasdaqMax * 1.01
            nasdaqMin = nasdaqMin * 0.99
            dowMax = dowMax * 1.01
            dowMin = dowMin * 0.99
            snpMax = snpMax * 1.01
            snpMin = snpMin * 0.99            
            
            for (let [index, val] of (response["NASDAQ"]["chart"]["result"][0]["timestamp"]).entries()){
                fmtedData.push(
                    {
                        "dowOpen": response["DOW"]["chart"]["result"][0]["indicators"]["quote"][0]["open"][index],
                        "nasdaqOpen": response["NASDAQ"]["chart"]["result"][0]["indicators"]["quote"][0]["open"][index],
                        "snpOpen": response["SNP"]["chart"]["result"][0]["indicators"]["quote"][0]["open"][index],
                        "time": this.getESTDate(val * 1000) + " EST",
                        "normalizedOpen": this.featureScaling(
                            parseFloat(nasdaqMin),
                            parseFloat(nasdaqMax),
                            parseFloat(response["NASDAQ"]["chart"]["result"][0]["indicators"]["quote"][0]["open"][index]))*0.333 +
                            this.featureScaling(
                                parseFloat(dowMin),
                                parseFloat(dowMax),
                                parseFloat(response["DOW"]["chart"]["result"][0]["indicators"]["quote"][0]["open"][index]))*0.333 +
                            this.featureScaling(
                                parseFloat(snpMin),
                                parseFloat(snpMax),
                                parseFloat(response["SNP"]["chart"]["result"][0]["indicators"]["quote"][0]["open"][index]))*0.333
                    }
                )
            }
            // Uncomment below to se computed data for graph
            // console.log(fmtedData)
            this.createGraph(fmtedData)
            window.addEventListener('scroll', this.listenScrollEvent)
            this.setState({fmtedData: fmtedData})
        
    })
  }

  highLightLast(){
    var lastIndex = this.state.fmtedData.length-1
    var orientation
    var left = document.getElementById(`id${lastIndex}`).getAttribute("cx") + "px"
    if(document.getElementById(`id${lastIndex}`).getAttribute("cx")/window.innerWidth > 0.95){
        orientation = "left"
        left = parseFloat(document.getElementById(`id${lastIndex}`).getAttribute("cx")) - 5 + "px"
    }else if(document.getElementById(`id${lastIndex}`).getAttribute("cx")/window.innerWidth < 0.05){
        orientation = "right"
        left = parseFloat(document.getElementById(`id${lastIndex}`).getAttribute("cx")) + 5 + "px"
    }else{
        orientation = "top"
    }

    select(`#id${lastIndex}`)
    .style("opacity", 1)
    select("body")
        .append("div")
        .style('position', 'absolute')
        .style('top', (parseFloat(document.getElementById(`id${lastIndex}`).getAttribute('cy')) + parseFloat(window.innerHeight*0.2) - 5) + "px")
        .style('left', left)
        .attr('data-toggle', 'tooltip')
        .attr('data-placement', orientation)
        .attr('data-html', 'true')
        .attr('title', 'DOW: ' + this.state.fmtedData[lastIndex].dowOpen + "<br>"
         + "NASDAQ: " + this.state.fmtedData[lastIndex].nasdaqOpen + "<br>" 
         + "S&P: " + this.state.fmtedData[lastIndex].snpOpen + "<br>"
         + this.state.fmtedData[lastIndex].time)
        .attr('id', 'datadiv')
        $('[data-toggle="tooltip"]').tooltip().tooltip("show")
  }

  createGraph(fmtedData){
    var graph = this.graph
    var width = document.getElementById('bannerContainer').clientWidth
    var height = document.getElementById('bannerContainer').clientHeight
    var orientation = "top"
    
    var xScale = scaleLinear()
    .domain([0,fmtedData.length-1])
    .range([0, Math.min((width/390)*fmtedData.length-1, width)])

    var yScale = scaleLinear()
    .domain([0,1])
    .range([height, 0])

    var graphLine = line()
    .x(function(d, i) { return xScale(i) })
    .y(function(d) { return yScale(d.normalizedOpen) })
    .curve(curveMonotoneX)

    var svg = select(graph)
    .attr("width", width)
    .attr("height", height)
    .attr("class", "svgClass")
    .style('--widthVal', Math.min((width/390)*fmtedData.length-1, width))
    .style('--timeScale', `${4 * (fmtedData.length/390)}s`)
    .append("g")

    svg.append("path")
    .datum(fmtedData)
    .attr("class", "line")
    .attr("d", graphLine)

    var graphArea = area()
    .x(function(d, i) { return xScale(i) })
    .y0(height)
    .y1(function(d) { return yScale(d.normalizedOpen) })

    svg.append("path")
       .data([fmtedData])
       .attr("class", "area")
       .attr("d", graphArea);

    svg.selectAll(".dot")
    .data(fmtedData)
    .enter().append("circle")
    .attr("class", "dot")
    .attr("id", function(d, i){return 'id' + i})
    .attr("cx", function(d, i) { return xScale(i) })
    .attr("cy", function(d) { return yScale(d.normalizedOpen) })
    .attr("r", 5)
    .style('opacity', '0')
    .on("mouseover", function(a, b, c){
        var left = c[b].getAttribute("cx") + "px"
        var top = (parseFloat(c[b].getAttribute("cy")) + parseFloat(window.innerHeight*0.2) - 5) + "px"
        if(c[b].getAttribute("cx")/width > 0.95){
            orientation = "left"
            left = parseFloat(c[b].getAttribute("cx"))-5 + "px"
        }else if(c[b].getAttribute("cx")/width < 0.05){
            orientation = "right"
            left = parseFloat(c[b].getAttribute("cx"))+5 + "px"
        }else{
            orientation = "top"
        }

        //Used for removing the initial datapoint highlight.
        //Method to call this only once?
        select(`#id${fmtedData.length-1}`)
        .style('opacity', 0)
        document.getElementById(`id${fmtedData.length-1}`).getAttribute('cx')/width > 0.95 ? 
        selectAll(".tooltip.bs-tooltip-left.fade.show").remove()
        : document.getElementById(`id${fmtedData.length-1}`).getAttribute('cx')/width < 0.05 ?
        selectAll(".tooltip.bs-tooltip-right.fade.show").remove()
        : selectAll(".tooltip.bs-tooltip-top.fade.show").remove()
        selectAll("#datadiv")
        .remove()

        select(this)
        .style('opacity', 1)
        select("body")
        .append("div")
        .style('position', 'absolute')
        .style('top', top)
        .style('left', left)
        .attr('data-toggle', 'tooltip')
        .attr('data-placement', orientation)
        .attr('data-html', 'true')
        .attr('title', 'DOW: ' + fmtedData[b].dowOpen + "<br>"
         + "NASDAQ: " + fmtedData[b].nasdaqOpen + "<br>" 
         + "S&P: " + fmtedData[b].snpOpen + "<br>"
         + fmtedData[b].time)
        .attr('id', 'datadiv')
        $('[data-toggle="tooltip"]').tooltip().tooltip("show")
    })
    .on("mouseout", function(a, b, c) {
        select(this)
        .style('opacity', 0)
        orientation === "top" ? selectAll(".tooltip.bs-tooltip-top.fade.show").remove()
        : orientation === "left" ? selectAll(".tooltip.bs-tooltip-left.fade.show").remove()
        : selectAll(".tooltip.bs-tooltip-right.fade.show").remove()
        selectAll("#datadiv")
        .remove()
    })   
  }


    render(){
        return(
            <div className="topLevelDiv">
                <Header 
                homeRef={this.home}
                aboutRef={this.about}
                portfolioRef={this.portfolio}
                contactRef={this.contact}></Header>                
                <img className="homebgimg" src={window.innerWidth > 1000 ? StarBG : StarBGMobile} alt="1920x1080"/>
                <div className="landingWelcome" id="home" ref={this.home}>
                    <div className="introTextBox">
                        <p className="lineOneIntro">Hi! My name is Gustavo Flores</p>
                        <p className="lineTwoIntro">Come check out some cool stuff I'm building</p>
                    </div>

                </div>
                <div className="bannerContainer" id="bannerContainer">
                    <svg 
                        ref={graph => this.graph = graph}
                        onAnimationEnd={() => {this.highLightLast()}}    
                    >
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#72BFDC" />
                            <stop offset="100%" stopColor="white" />
                        </linearGradient>
                    </svg>
                    
                </div>

                <div className="aboutme" id="aboutme" ref={this.about}>
                    <h2>ABOUT ME</h2>
                    <p className="aboutmetext">Born in Brazil, raised in NJ and currently residing in NYC. I studied Computer Science at Rutgers University and developed skills from
                    computer hardware repair, to factory line automation, full-stack development and cloud engineering. I've recently taken on a new role as a Customer Engineer at Google where I'll be able to
                    bring my software engineering/cloud skills to another level while helping clients understand GCP! Outside of work you can find me skateboarding, iceskating, gaming, and building, developing, 
                    maintaining websites such as this one and more!</p>
                    <br/>
                    <img src={Headshot} className="headshot" alt=""/>
                    {/* <h3>Insert image of me here</h3> */}
                </div>

                <div className="portfoliodiv" id="portfolio" ref={this.portfolio}>
                    <h2>Portfolio</h2>
                    <Portfolio
                        img={BoaVista}
                        href="https://www.boavistabbq.com"
                        innerText="Boavista"
                        description="Designed, wrote and maintaining restaurant's website"
                    />
                    <br/>
                    <Portfolio
                        img={BlogImg}
                        href="https://medium.com/@floresgustavo32"
                        innerText="Blog"
                        description="I write about things I wanted to know but couldn't find"
                    />                    
                </div>

                <div id="contact" ref={this.contact}>
                    <h2>Contact me!</h2>
                    <p className="contactmedesc">If you like what you see want to chat, from building your own site to emerging technologies feel free to contact me through these channels:</p>
                    <div className="contactbox">
                        <Contact
                            innerText="RESUME"
                            href={`https://s3.amazonaws.com/${this.state.bucketName}/data/Resume+-+Gustavo+Flores.pdf`}
                            img={Resume}
                        />

                        <Contact
                            innerText="LINKEDIN"
                            href="https://www.linkedin.com/in/floresgustavo32/"
                            img={Linkedin}
                        />

                        <Contact
                            innerText="GITHUB"
                            href="https://github.com/GFlores94"
                            img={GitIcon}
                        />
                    </div>
                    <p className="contactmedesc">You can also email me at <a href="mailto:floresgustavo32@gmail.com">floresgustavo32@gmail.com</a></p>

                </div>

                <Footer/>
            </div>
        );
    }

}

export default Home;