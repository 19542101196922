//Version 1.4.0 as of 5/18/2021

import React from 'react';
import './App.css';
import Home from './home?v=1.4.0'

function App() {
  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;
